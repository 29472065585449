export function JoinChatButton() {
  return (
    <button
      className="relative flex h-[31px] w-[150px] items-center justify-center gap-2 font-diablo text-sm uppercase"
      style={{
        borderImage: "url('/assets/buttons/button-gray.png') stretch",
        borderImageWidth: "auto",
        borderImageSlice: "110 0 0 0",
      }}
    >
      Join chat
      <ChatIcon />
    </button>
  );
}

function ChatIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 15 13"
      className="h-4 w-4"
      fill="none"
    >
      <path
        d="M10.1573 2.10009C10.0985 2.08244 10.0397 2.06479 9.97497 2.05303C9.51026 1.9648 9.0691 1.93538 8.63969 1.9648C8.2044 1.98832 7.78677 2.07068 7.36912 2.20596C5.27504 2.8883 3.86914 4.82953 3.86914 7.03523C3.86914 7.147 3.87502 7.25876 3.88091 7.39404C3.89267 7.55286 3.91032 7.71168 3.93973 7.86462C3.93973 7.87639 3.93973 7.88815 3.94561 7.89992C3.96326 8.02933 3.99267 8.15873 4.02796 8.28225C4.06325 8.44107 4.11031 8.594 4.16914 8.74696C4.19266 8.82343 4.22208 8.89401 4.25149 8.96459C4.2809 9.04694 4.31619 9.1293 4.35737 9.20576C4.36913 9.23517 4.3809 9.2587 4.39266 9.28222C4.51619 9.52339 4.65148 9.75868 4.81031 9.97633C4.85737 10.041 4.90443 10.1057 4.95148 10.1646C5.9103 11.3939 7.38683 12.1175 8.95141 12.1175H14.5571C14.6924 12.1175 14.816 12.0586 14.8983 11.9528C14.9865 11.8528 15.016 11.7175 14.9924 11.5881L14.0336 6.98805C14.0101 4.67054 12.4042 2.65289 10.1572 2.09986L10.1573 2.10009ZM7.88084 4.64122H10.275C10.5161 4.64122 10.7161 4.84121 10.7161 5.0824C10.7161 5.32358 10.5161 5.52357 10.275 5.52357H7.88084C7.63968 5.52357 7.43966 5.32358 7.43966 5.0824C7.43966 4.84121 7.63966 4.64122 7.88084 4.64122ZM10.275 9.44696H7.88084C7.63968 9.44696 7.43966 9.24697 7.43966 9.00579C7.43966 8.7646 7.63966 8.56461 7.88084 8.56461H10.275C10.5161 8.56461 10.7161 8.7646 10.7161 9.00579C10.7161 9.24697 10.5161 9.44696 10.275 9.44696ZM10.8632 7.4822H7.29262C7.05145 7.4822 6.85144 7.2881 6.85144 7.04102C6.85144 6.79986 7.05144 6.59984 7.29262 6.59984H10.8632C11.1044 6.59984 11.3044 6.79984 11.3044 7.04102C11.3044 7.28808 11.1044 7.4822 10.8632 7.4822Z"
        fill="#FFF8EE"
      />
      <path
        d="M3.86903 9.55277C3.85139 9.51159 3.83374 9.4763 3.81021 9.42924C3.78668 9.38218 3.73374 9.27042 3.69845 9.15866C3.66904 9.09983 3.63374 9.0116 3.60433 8.91749C3.55139 8.77632 3.49257 8.59396 3.4514 8.41162C3.42787 8.3175 3.39846 8.20573 3.38081 8.08809L3.35728 8.04691L3.3514 7.92927C3.32199 7.77045 3.30434 7.60573 3.29257 7.43516V7.34104C3.28669 7.22928 3.28081 7.13515 3.28081 7.03517C3.28081 4.57046 4.85138 2.40579 7.18662 1.64703C7.66307 1.48821 8.12778 1.40587 8.61012 1.37645C8.72189 1.37056 8.83954 1.36468 8.96306 1.36468C9.15129 1.36468 9.3454 1.37645 9.54541 1.39997C8.61602 0.505873 7.36305 0 6.04543 0C3.25734 0 0.986697 2.25295 0.963168 5.0352L0.010244 9.63521C-0.0191672 9.76462 0.0161262 9.8999 0.0984777 9.99991C0.180829 10.1058 0.310239 10.1646 0.439648 10.1646H4.22198C4.10433 9.98815 3.99255 9.78806 3.86903 9.55277Z"
        fill="#FFF8EE"
      />
    </svg>
  );
}
