export function PenIcon({ className }: { className?: string }): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        d="M10.4212 9.84399H0.578956C0.425407 9.84399 0.278147 9.90499 0.169572 10.0136C0.0609969 10.1221 0 10.2694 0 10.4229C0 10.5765 0.0609969 10.7238 0.169572 10.8323C0.278147 10.9409 0.425407 11.0019 0.578956 11.0019H10.4212C10.5748 11.0019 10.722 10.9409 10.8306 10.8323C10.9392 10.7238 11.0002 10.5765 11.0002 10.4229C11.0002 10.2694 10.9392 10.1221 10.8306 10.0136C10.722 9.90499 10.5748 9.84399 10.4212 9.84399Z"
        fill="#FFF8EE"
      />
      <path
        d="M2.02679 9.26487H3.1847C3.2228 9.26509 3.26056 9.25779 3.29583 9.24338C3.3311 9.22898 3.36318 9.20775 3.39023 9.18092L8.89031 3.68084C8.91744 3.65393 8.93898 3.62192 8.95367 3.58664C8.96837 3.55136 8.97594 3.51353 8.97594 3.47531C8.97594 3.4371 8.96837 3.39926 8.95367 3.36399C8.93898 3.32871 8.91744 3.29669 8.89031 3.26978L7.7324 2.11187C7.70549 2.08474 7.67347 2.0632 7.63819 2.04851C7.60292 2.03381 7.56508 2.02625 7.52687 2.02625C7.48865 2.02625 7.45082 2.03381 7.41554 2.04851C7.38027 2.0632 7.34825 2.08474 7.32134 2.11187L1.82126 7.61195C1.79443 7.639 1.7732 7.67108 1.7588 7.70635C1.74439 7.74162 1.73709 7.77938 1.73731 7.81748V8.97539C1.73731 9.05217 1.76781 9.1258 1.8221 9.18009C1.87638 9.23437 1.95001 9.26487 2.02679 9.26487Z"
        fill="#FFF8EE"
      />
      <path
        d="M9.34418 2.81251C9.37157 2.83969 9.40412 2.86111 9.43991 2.87553C9.4757 2.88995 9.51402 2.89706 9.5526 2.89646C9.62854 2.89614 9.70132 2.86599 9.75523 2.81251L10.9131 1.6546C10.9403 1.62769 10.9618 1.59567 10.9765 1.56039C10.9912 1.52512 10.9988 1.48728 10.9988 1.44907C10.9988 1.41085 10.9912 1.37302 10.9765 1.33774C10.9618 1.30247 10.9403 1.27045 10.9131 1.24354L9.75523 0.0856271C9.72832 0.0584948 9.69631 0.0369593 9.66103 0.0222629C9.62576 0.00756651 9.58792 0 9.54971 0C9.51149 0 9.47365 0.00756651 9.43838 0.0222629C9.4031 0.0369593 9.37109 0.0584948 9.34418 0.0856271L8.18626 1.24354C8.13322 1.29765 8.10352 1.3704 8.10352 1.44617C8.10352 1.52194 8.13322 1.5947 8.18626 1.64881L9.34418 2.81251Z"
        fill="#FFF8EE"
      />
    </svg>
  );
}
