import { TrustBadgeIcon } from "@/components/icons/trust-badge-icon";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/avatar";

DropdownUserSection.IconTrustBadge = IconTrustBadge;

export function DropdownUserSection({
  name,
  image,
  icon,
  className,
}: {
  name: string;
  image: string;
  icon: JSX.Element;
  className: string;
}): JSX.Element {
  return (
    <div className={className}>
      <div className="flex max-w-[185px] items-center gap-x-2 truncate">
        <Image name={image} />

        <UserName name={name} />
      </div>

      {icon}
    </div>
  );
}

function IconTrustBadge(): JSX.Element {
  return (
    <div>
      <TrustBadgeIcon />
    </div>
  );
}

function UserName({ name }: { name: string }): JSX.Element {
  return (
    <span className="truncate font-diablo text-[12px] uppercase">{name}</span>
  );
}

function Image({ name }: { name: string }): JSX.Element {
  return (
    <Avatar asChild>
      <button
        className="items-center justify-center rounded bg-background-subtle"
        type="button"
      >
        <AvatarImage alt="@shadcn" className="rounded" src={name} width={50} />
        <AvatarFallback>CN</AvatarFallback>
      </button>
    </Avatar>
  );
}
