"use client";

import { format } from "date-fns";
import { Section } from "../section";
import { CoinIcon } from "@/components/icons/coins-icon";
import { TrustBadgeIcon } from "@/components/icons/trust-badge-icon";

export function AuctionDetails(props: React.HTMLAttributes<HTMLDivElement>) {
  return <Section {...props} />;
}

AuctionDetails.Header = Section.Header;
AuctionDetails.Body = Section.Body;
AuctionDetails.Title = Title;
AuctionDetails.Menu = Section.Menu;

AuctionDetails.Seller = Seller;
AuctionDetails.StartingBid = StartingBid;
AuctionDetails.BidIncrement = BidIncrement;
AuctionDetails.HighestBid = HighestBid;
AuctionDetails.ActiveBids = ActiveBids;
AuctionDetails.TimeRemaning = TimeRemaning;

function Title() {
  return <Section.Title>Auction Details</Section.Title>;
}

function Seller() {
  return (
    <Row
      title="Seller:"
      value={
        <div className="flex items-center gap-2">
          <DiamondIcon />
          <p className="font-diablo">General Mort</p>
          <div className="flex h-[15px] w-[25px] items-center justify-between border border-green-200 bg-green-200/20 px-1 text-[11px] text-green-200">
            <TrustBadgeIcon />
          </div>
        </div>
      }
    />
  );
}

function StartingBid({ amount }: { amount: number }) {
  return (
    <Row
      title="Starting Bid:"
      value={<Coin>{Intl.NumberFormat("en").format(amount)}</Coin>}
    />
  );
}
function BidIncrement({ amount }: { amount: number }) {
  return (
    <Row
      title="Bid increment:"
      value={<Coin>{Intl.NumberFormat("en").format(amount)}</Coin>}
    />
  );
}
function HighestBid({ amount }: { amount: number }) {
  return (
    <Row
      title="Highest Bid:"
      value={<Coin>{Intl.NumberFormat("en").format(amount)}</Coin>}
    />
  );
}
function ActiveBids({ amount }: { amount: number }) {
  return <Row title="Active Bid:" value={`${amount} bids`} />;
}
function TimeRemaning({ datetime }: { datetime: string }) {
  return (
    <Row
      title="Time Remaning:"
      value={`${format(datetime, "H'h' m'min'")} Left`}
    />
  );
}

function Row({
  title,
  value,
}: {
  title: React.ReactNode;
  value: React.ReactNode;
}) {
  return (
    <div className="grid grid-cols-[1fr_auto] items-center justify-between p-2  [&:not(:last-child)]:border-b [&:not(:last-child)]:border-gray-700">
      <div className="font-diablo text-sm">{title}</div>
      <div className="font-diablo text-sm">{value}</div>
    </div>
  );
}

function Coin({ children }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="flex items-center">
      <CoinIcon className="mr-1 h-3 w-3 text-yellow-100" />
      {children}
    </div>
  );
}

function DiamondIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 13 13"
      fill="none"
      className="h-4 w-4"
    >
      <path
        d="M5.08579 1.82843C5.86683 1.04738 7.13316 1.04738 7.91421 1.82843L11.1716 5.08579C11.9526 5.86683 11.9526 7.13317 11.1716 7.91421L7.91421 11.1716C7.13317 11.9526 5.86684 11.9526 5.08579 11.1716L1.82843 7.91421C1.04738 7.13317 1.04738 5.86684 1.82843 5.08579L5.08579 1.82843Z"
        stroke="#00B259"
        strokeWidth="2"
      />
    </svg>
  );
}
