import { twMerge } from "tailwind-merge";
import { Select } from "@repo/ui/select";

export function ActivityFilters() {
  return (
    <div className={twMerge("mb-4 grid grid-cols-2 gap-2 px-2")}>
      <Select>
        <Select.Trigger className="font-diablo">
          <Select.Value className="bg-primary " placeholder="Select" />
        </Select.Trigger>
        <Select.Content className="text-xs">
          <Select.Group>
            {Array.from({ length: 4 }).map((_, i) => {
              return (
                <Select.Item
                  className="font-diablo text-base"
                  key={i}
                  value={String(i)}
                >
                  Option {i}
                </Select.Item>
              );
            })}
          </Select.Group>
        </Select.Content>
      </Select>
      <Select>
        <Select.Trigger className="font-diablo">
          <Select.Value placeholder="Select" />
        </Select.Trigger>
        <Select.Content>
          <Select.Group>
            {Array.from({ length: 4 }).map((_, i) => {
              return (
                <Select.Item
                  className="font-diablo text-base"
                  key={i}
                  value={String(i)}
                >
                  Option {i}
                </Select.Item>
              );
            })}
          </Select.Group>
        </Select.Content>
      </Select>
    </div>
  );
}
