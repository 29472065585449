"use client";

import { useEffect, useState } from "react";
import { useIMask } from "react-imask";
import { PlusIcon, MinusIcon, CheckIcon } from "lucide-react";
import { CoinIcon } from "@/components/icons/coins-icon";
import { PenIcon } from "@/components/icons/pen-icon";
import { cn } from "@repo/ui/utils";
import type { Send, Step } from "../../step-machine";

type Percent = 1 | 2 | 5 | 10;

function getFullNumber(value: string | number): number {
  if (!value) {
    return 0;
  }
  const cleanValue = value.toString().replace(/,/g, "").trim();
  const suffixMatch = cleanValue.match(/^(\d+)([BMK])?$/i);
  if (suffixMatch) {
    const [, numberPart, suffix] = suffixMatch;
    const multipliers: Record<string, number> = {
      B: 1_000_000_000,
      M: 1_000_000,
      K: 1_000,
    };

    return suffix
      ? parseFloat(numberPart) * multipliers[suffix.toUpperCase()]
      : parseFloat(numberPart);
  }
  const numericValue = cleanValue.replace(/[^\d.]/g, "");

  return parseFloat(numericValue) || 0;
}

export function IntialStep({
  step,
  children,
}: {
  step: Step;
  children?: React.ReactNode;
}) {
  if (step !== "initial") {
    return null;
  }
  return <div className="grid  justify-items-center gap-3">{children}</div>;
}

IntialStep.Bid = Bid;
IntialStep.Buyout = Buyout;

function Bid({ send, minimum = 200000000 }: { send: Send; minimum?: number }) {
  const [editing, setEditing] = useState(false);
  const [percent, setPercent] = useState<Percent>(1);

  const { ref, value, setValue } = useIMask({
    mask: [
      {
        mask: "num",
        blocks: {
          num: {
            mask: Number,
            thousandsSeparator: ",",
          },
        },
      },
      /^[0-9]+[MBK]?$/,
    ],
    prepareChar: (val) => {
      return val.toUpperCase();
    },
  });

  const onDecrease = () => {
    const fullNumber = getFullNumber(value);
    const newValue = Math.max(minimum, fullNumber - minimum * (percent / 100));
    setValue(Intl.NumberFormat("en", { notation: "compact" }).format(newValue));
  };

  const onIncrease = () => {
    const fullNumber = getFullNumber(value);
    const newValue = Math.max(minimum, fullNumber + minimum * (percent / 100));
    setValue(Intl.NumberFormat("en", { notation: "compact" }).format(newValue));
  };

  const onBlur = () => {
    const fullNumber = getFullNumber(value);
    if (fullNumber < minimum) {
      setValue(
        Intl.NumberFormat("en", { notation: "compact" }).format(minimum),
      );
      return;
    }
    setValue(
      Intl.NumberFormat("en", { notation: "compact" }).format(fullNumber),
    );
  };

  useEffect(() => {
    const fullNumber = getFullNumber(value);
    if (fullNumber < minimum) {
      setValue(
        Intl.NumberFormat("en", { notation: "compact" }).format(minimum),
      );
    }
  }, []);

  return (
    <div className="grid justify-items-center gap-1">
      <div className="flex items-end">
        <div className="flex h-7 items-center">
          <button
            type="button"
            className={cn(
              "mr-1 flex h-4 w-4 items-center justify-center rounded-sm bg-red-200 transition-all  delay-100  [&:not(:disabled)]:hover:scale-125",
            )}
            onClick={onDecrease}
          >
            <MinusIcon className="h-3 w-3" />
          </button>
        </div>
        <div>
          <h2 className="mb-1 text-center font-diablo text-sm uppercase">
            BID
          </h2>
          <div
            className={cn(
              "relative flex h-7 w-[120px] items-center justify-center overflow-hidden font-diablo text-[16px]",
              {
                "hover:scale-105 hover:shadow-md hover:shadow-blue-100/10":
                  !editing,
              },
            )}
            style={{
              borderImage: "url('/assets/buttons/button-blue.webp') stretch",
              borderImageWidth: "auto",
              borderImageSlice: "110 0 0 0",
            }}
          >
            <div className="absolute left-2">
              <CoinIcon className="w- mr-1 h-3 w-3 text-yellow-100" />
            </div>
            <input
              type={editing ? "text" : "button"}
              ref={(el) => {
                ref.current = el;
              }}
              className={cn(
                "bg-transparent w-full pl-6 pr-4 text-left text-center outline-none",
                {
                  "text-left ": editing,
                  "cursor-pointer ": !editing,
                },
              )}
              onClick={() => {
                if (!editing) {
                  send("BID");
                }
              }}
              onBlur={onBlur}
              style={{ backgroundColor: "transparent" }}
            />
          </div>
        </div>
        <div className="flex h-7 items-center">
          <button
            type="button"
            onClick={onIncrease}
            className={cn(
              "ml-1 flex h-4 w-4 items-center justify-center rounded-sm bg-green-200 transition-all  delay-100 [&:not(:disabled)]:hover:scale-125",
            )}
          >
            <PlusIcon className="h-3 w-3" />
          </button>
        </div>
      </div>
      <div className="grid gap-1">
        <div className="flex items-center gap-1">
          <IncrementBtn
            selected={percent === 1}
            onClick={() => {
              setPercent(1);
            }}
          >
            1x
          </IncrementBtn>
          <IncrementBtn
            selected={percent === 2}
            onClick={() => {
              setPercent(2);
            }}
          >
            2x
          </IncrementBtn>
          <IncrementBtn
            selected={percent === 5}
            onClick={() => {
              setPercent(5);
            }}
          >
            5x
          </IncrementBtn>
          <IncrementBtn
            selected={percent === 10}
            onClick={() => {
              setPercent(10);
            }}
          >
            10x
          </IncrementBtn>

          <button
            type="button"
            className={cn(
              "flex h-7 w-7 items-center justify-center rounded-sm border border-gray-600 text-xs ",
              editing ? "bg-green-200" : "bg-gray-600",
              "focus:bg-gray-600",
              !editing && "hover:border-gray-200",
            )}
            onClick={() => {
              setEditing((prev) => !prev);
              if (!editing && ref.current) {
                (ref.current as HTMLInputElement).focus();
              }
            }}
          >
            {editing ? (
              <CheckIcon className="h-4 w-4" />
            ) : (
              <PenIcon className="h-3 w-3" />
            )}
          </button>
        </div>
        <p className="text-center font-sans  text-xs font-bold">
          <span className="text-gray-200">Increment:</span>
          <span className="text-gray-100">
            +
            {Intl.NumberFormat("en", { notation: "compact" }).format(
              (getFullNumber(minimum) * percent) / 100,
            )}
          </span>
        </p>
      </div>
    </div>
  );
}

function IncrementBtn({
  selected,
  children,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & {
  selected: boolean;
}) {
  return (
    <button
      {...rest}
      type="button"
      className={cn(
        "h-7 w-7 rounded-sm border border-gray-600 bg-gray-600 font-sans text-xs transition-all duration-200 disabled:opacity-75  [&:not(:disabled)]:hover:border-gray-200",
        {
          "border border-gray-200": selected,
        },
      )}
    >
      {children}
    </button>
  );
}

function Buyout({ value, send }: { value: number; send: Send }) {
  return (
    <div>
      <h2 className="mb-1 text-center font-diablo text-sm uppercase">Buyout</h2>
      <div className="flex items-center justify-center">
        <button
          type="button"
          onClick={() => {
            send("BUYOUT");
          }}
          className=" flex h-7 w-[118px] items-center justify-center font-diablo text-[16px]  hover:scale-105 hover:shadow-md hover:shadow-green-100/10"
          style={{
            borderImage: "url('/assets/buttons/button-green.webp') stretch",
            borderImageWidth: "auto",
            borderImageSlice: "110 0 0 0",
          }}
        >
          <CoinIcon className="mr-1 h-3 w-3 text-yellow-100" />
          {Intl.NumberFormat("en", { notation: "compact" }).format(value)}
        </button>
      </div>
    </div>
  );
}
